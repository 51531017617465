import React, { useEffect, useState } from "react"
import withGuestLayout from "../../hoc/withGuestLayout"
import { useGuestApi } from "../../hooks/useApi"
import MainContent from "../Layout/MainContent"
import BodyContent from "../Text/BodyContent"
import Title from "../Text/Title"
import { getTitle } from "../../helpers/Publications"
import { returnLayoutSection } from "../../helpers/Layout"

function CompetitionRules() {
  const { getCompetitionRulesPublication } = useGuestApi();
  const [content, setContent] = useState(null);

  useEffect(() => {
    getCompetitionRulesPublication().then(response => {
      setContent(response);
    })
  }, [getCompetitionRulesPublication])

  if(!content) {
    return null;
  }

  return (
    <MainContent headerWidth={960} headerHeight={312} {...content}>
      <Title>{getTitle(content.title)}</Title>
      <BodyContent>
      {content.layout
        ? Array.isArray(content.layout.items)
          ? content.layout.items.map((item, i) => {
              return returnLayoutSection(item, i)
            })
          : null
        : null
      }
      </BodyContent>
    </MainContent>
  )
}

export default withGuestLayout(CompetitionRules, {
  page: "Wedstrijdreglement",
})
